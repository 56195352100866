<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Cobertura -->
            <v-col cols="12" class="py-0" md="12">
              <v-text-field
                v-model.trim="coberturaNom"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Cobertura"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Convenios -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="conveniosSelect"
                :items="conveniosItems"
                label="Convenio"
                item-text="value"
                item-value="id"
                outlined
                dense
                :rules="rules.required"
                @change="setPlanesByConvenios()"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <!-- Planes -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="planesSelected"
                :items="planesItems"
                item-text="value"
                item-value="id"
                label="Plan"
                outlined
                dense
                :rules="rules.required"
                return-object
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Nomenclador -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="nomencladorSelected"
                :items="nomencladorItems"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
                autocomplete="on"
                label="Nomenclador"
                :rules="rules.required"
                return-object
                @change="changeNomenclador"
              >
              </v-autocomplete>
            </v-col>
            <!-- Edad desde -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                v-model.trim="edadDesde"
                type="text"
                outlined
                dense
                clearable
                label="Edad desde"
                v-mask="'###'"
                :rules="
                  rules.positiveNumber.concat([
                    rules.validDateRange(
                      parseInt(edadDesde),
                      parseInt(edadHasta)
                    )
                  ])
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Edad hasta -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                v-model.trim="edadHasta"
                type="text"
                outlined
                dense
                clearable
                label="Edad hasta"
                v-mask="'###'"
                :rules="
                  rules.positiveNumber.concat([
                    rules.validDateRange(
                      parseInt(edadDesde),
                      parseInt(edadHasta)
                    )
                  ])
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Codigo desde -->
            <v-col cols="12" class="py-0" md="4">
              <v-text-field
                v-model.trim="codigoDesde"
                type="text"
                outlined
                dense
                clearable
                :disabled="nomencladorSelected == null"
                label="Código desde"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.validDateRange(
                      parseInt(codigoDesde),
                      parseInt(codigoHasta)
                    )
                  )
                "
                v-mask="'#########'"
                autocomplete="off"
                @change="buscarCodigoDesde"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo desde -->
            <v-col cols="12" class="py-0" md="7">
              <v-text-field
                v-model.trim="descCodigoDesde"
                type="text"
                outlined
                dense
                clearable
                label="Descripción código desde"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo desde -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="nomencladorSelected == null"
                    @click="modalBusquedaCodigoDesde = true"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código desde" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- Codigo hasta -->
            <v-col cols="12" class="py-0" md="4">
              <v-text-field
                v-model.trim="codigoHasta"
                type="text"
                outlined
                dense
                clearable
                :disabled="nomencladorSelected == null"
                label="Código hasta"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.validDateRange(
                      parseInt(codigoDesde),
                      parseInt(codigoHasta)
                    )
                  )
                "
                v-mask="'#########'"
                @change="buscarCodigoHasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo hasta -->
            <v-col cols="12" class="py-0" md="7">
              <v-text-field
                v-model.trim="descCodigoHasta"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Descripción código hasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo desde -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="nomencladorSelected == null"
                    @click="modalBusquedaCodigoHasta = true"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código hasta" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- Fecha vigencia hasta-->
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                ref="menu-fecha-vigencia"
                v-model="menuFechaVigencia"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaVigenciaSelected"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="
                      fechaVigencia = parseDateToIso(fechaVigenciaSelected)
                    "
                    v-on="on"
                    :rules="
                      fechaVigencia != null ? rules.validDate : rules.required
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVigencia"
                  no-title
                  @change="fechaVigenciaSelected = formatDate(fechaVigencia)"
                  @input="menuFechaVigencia = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Tope -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                v-model.trim="tope"
                type="text"
                outlined
                dense
                clearable
                label="Tope"
                autocomplete="off"
                v-mask="'###'"
                :rules="rules.requiredAcceptZero.concat(rules.positiveNumber)"
              ></v-text-field>
            </v-col>
            <!-- Dias -->
            <v-col cols="12" class="py-0" md="3">
              <v-text-field
                v-model.trim="dias"
                type="text"
                outlined
                dense
                clearable
                label="Días"
                autocomplete="off"
                v-mask="'###'"
                :rules="rules.requiredAcceptZero.concat(rules.positiveNumber)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de autorizacion -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="tipoAutorizacionSelect"
                :items="tiposAutorizacionItems"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
                autocomplete="on"
                label="Tipo de autorización"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <!-- Sexo -->
            <v-col cols="12" md="2" class="py-0">
              <v-autocomplete
                v-model="generoSelected"
                :items="generos"
                item-text="value"
                item-value="value"
                label="Sexo"
                :disabled="TopecoberturaEspecialObj != null"
                :rules="rules.required"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Check controlar codigos del rango -->
            <v-col cols="12" md="7" class="py-0">
              <v-switch
                v-model="controlarRango"
                class="my-0"
                label="Controlar todos los códigos del rango."
              ></v-switch>
            </v-col>
            <!-- Check tope por año calendario -->
            <v-col cols="12" md="5" class="py-0">
              <v-switch
                v-model="topePorAno"
                class="my-0"
                label="Tope por año calendario."
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
    <!-- modal para buscar codigo desde -->
    <v-dialog
      v-model="modalBusquedaCodigoDesde"
      v-if="modalBusquedaCodigoDesde"
      @keydown.esc="toggleModalBusquedaCodigoDesde"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="toggleModalBusquedaCodigoDesde"
        @findCodigo="findCodigoDesde"
        :nomencladorObject="nomencladorSelected"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
    <!-- modal para buscar codigo hasta -->
    <v-dialog
      v-model="modalBusquedaCodigoHasta"
      v-if="modalBusquedaCodigoHasta"
      @keydown.esc="toggleModalBusquedaCodigoHasta"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="toggleModalBusquedaCodigoHasta"
        @findCodigo="findCodigoHasta"
        :nomencladorObject="nomencladorSelected"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";
export default {
  directives: { mask },
  name: "EditTopesCoberturaEspecial",
  components: {
    BusquedaCodigoNomenclador
  },
  props: {
    TopecoberturaEspecialObj: {
      type: Object,
      default: null
    },
    coberturaObjectNomId: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: rules,
      formEditTitle: enums.titles.EDIT_TOPE_POR_TIPO_COBERTURA,
      newTitle: enums.titles.NUEVO_TOPE_POR_TIPO_COBERTURA,
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      coberturaNom: "",
      edadDesde: "",
      edadHasta: "",
      codigoDesde: null,
      codigoHasta: null,
      tope: "",
      dias: "",
      descCodigoHasta: null,
      descCodigoDesde: null,
      generoSelected: null,
      generos: [{ value: "Ambos" }, { value: "M" }, { value: "F" }],
      nomencladorSelected: null,
      nomencladorItems: [],
      conveniosSelect: null,
      conveniosItems: [],
      planesSelected: null,
      planesItems: [],
      tipoAutorizacionSelect: null,
      tiposAutorizacionItems: [],
      menuFechaVigencia: null,
      fechaVigencia: null,
      fechaVigenciaSelected: null,
      controlarRango: false,
      topePorAno: false,
      isFormValid: false,
      loadingSaveBtn: false,
      modalBusquedaCodigoDesde: false,
      modalBusquedaCodigoHasta: false
    };
  },
  async created() {
    await this.setSelects();
    if (this.TopecoberturaEspecialObj) {
      this.setTopeCoberturaEspecial();
    } else {
      this.newTopeCoberturaEspecial();
    }
  },
  methods: {
    ...mapActions({
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getTiposAutorizacionesTaxonomy:
        "configAfiliaciones/getTiposAutorizacionesTaxonomy",
      getNomencladores: "afiliaciones/getNomencladores",
      getDescByNomencladorYCodigo: "afiliaciones/getDescByNomencladorYCodigo",
      saveTopePorTipoCoberturaEspecial:
        "afiliaciones/saveTopePorTipoCoberturaEspecial",
      setAlert: "user/setAlert"
    }),
    setTopeCoberturaEspecial() {
      this.coberturaNom = this.coberturaObjectNomId.tcobNombre;
      this.edadDesde = this.TopecoberturaEspecialObj.edadDesde;
      this.edadHasta = this.TopecoberturaEspecialObj.edadHasta;
      this.conveniosSelect = this.TopecoberturaEspecialObj.os;
      this.planesSelected = this.TopecoberturaEspecialObj.plan;
      this.nomencladorSelected = this.TopecoberturaEspecialObj.nomenclador;
      this.codigoDesde = this.TopecoberturaEspecialObj.codigoDesde;
      this.codigoHasta = this.TopecoberturaEspecialObj.codigoHasta;
      (this.descCodigoHasta = this.TopecoberturaEspecialObj.nombreHasta),
        (this.descCodigoDesde = this.TopecoberturaEspecialObj.nombreDesde),
        (this.generoSelected = this.TopecoberturaEspecialObj.sexo);
      this.fechaVigenciaSelected = this.TopecoberturaEspecialObj.vigencia;
      this.fechaVigencia = this.parseDateToIso(
        this.TopecoberturaEspecialObj.vigencia
      );
      this.tope = this.TopecoberturaEspecialObj.tope;
      this.dias = this.TopecoberturaEspecialObj.dias;
      this.tipoAutorizacionSelect = this.TopecoberturaEspecialObj.ta;
      this.controlarRango = this.TopecoberturaEspecialObj.controlarTodoCodRango;
      this.topePorAno = this.TopecoberturaEspecialObj.añoCalendario;
      this.setPlanesByConvenios();
    },
    newTopeCoberturaEspecial() {
      this.formEditTitle = this.newTitle;
      this.coberturaNom = this.coberturaObjectNomId.tcobNombre;
    },
    async setSelects() {
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
      this.conveniosItems.unshift({ id: 0, value: "Todos" });
      const planes = await this.getPlanesTaxonomy();
      this.planesItems = planes;
      this.planesItems.unshift({ id: -1, value: "Todos" });
      const tiposAutorizacion = await this.getTiposAutorizacionesTaxonomy();
      this.tiposAutorizacionItems = tiposAutorizacion.filter(objeto => {
        return objeto.id === 2 || objeto.id === 4;
      });
      const nomencladores = await this.getNomencladores();
      this.nomencladorItems = nomencladores;
    },
    async setPlanesByConvenios() {
      if (this.conveniosSelect.id === 0) {
        const planes = await this.getPlanesTaxonomy();
        this.planesItems = planes;
      } else {
        const planes = await this.getPlanesByConvenio([
          this.conveniosSelect.id
        ]);
        this.planesItems = planes;
      }
      this.planesItems.unshift({ id: -1, value: "Todos" });
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        tipoCobTopeId: this.TopecoberturaEspecialObj
          ? this.TopecoberturaEspecialObj.tipoCobTopeId
          : null,
        idTipoCob: this.coberturaObjectNomId.idTipocob,
        osId: this.conveniosSelect.id,
        sexo: this.generoSelected,
        edadDesde: this.edadDesde,
        edadHasta: this.edadHasta,
        nomencladorId: this.nomencladorSelected.id,
        codigoDesde: this.codigoDesde,
        codigoHasta: this.codigoHasta,
        vigencia: this.fechaVigencia,
        tope: this.tope,
        dias: this.dias,
        taId: this.tipoAutorizacionSelect.id,
        planId: this.planesSelected.id,
        añoCalendario: this.topePorAno,
        controlarTodoCodRango: this.controlarRango
      };
      try {
        const res = await this.saveTopePorTipoCoberturaEspecial(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    async buscarCodigoDesde() {
      if (this.codigoDesde) {
        const data = {
          nomenclador: this.nomencladorSelected.id,
          codigo: this.codigoDesde
        };
        const response = await this.getDescByNomencladorYCodigo(data);
        if (response.nombre) {
          this.descCodigoDesde = response.nombre;
        } else {
          this.descCodigoDesde = null;
        }
      } else {
        this.descCodigoDesde = null;
      }
    },
    async buscarCodigoHasta() {
      if (this.codigoHasta) {
        const data = {
          nomenclador: this.nomencladorSelected.id,
          codigo: this.codigoHasta
        };
        const response = await this.getDescByNomencladorYCodigo(data);
        if (response.nombre) {
          this.descCodigoHasta = response.nombre;
        } else {
          this.descCodigoHasta = null;
        }
      } else {
        this.descCodigoHasta = null;
      }
    },
    changeNomenclador() {
      this.codigoDesde = null;
      this.descCodigoDesde = null;
      this.codigoHasta = null;
      this.descCodigoHasta = null;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    findCodigoDesde(codigoSelected) {
      this.codigoDesde = codigoSelected.nomCod;
      this.descCodigoDesde = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoDesde() {
      this.modalBusquedaCodigoDesde = !this.modalBusquedaCodigoDesde;
    },
    findCodigoHasta(codigoSelected) {
      this.codigoHasta = codigoSelected.nomCod;
      this.descCodigoHasta = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoHasta() {
      this.modalBusquedaCodigoHasta = !this.modalBusquedaCodigoHasta;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
